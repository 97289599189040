<template>
	<vvp v-slot="v" :rules="rules" :vid="vid">
		{{ setFailedRules(v) }}
		<b-form-textarea :type="inputType" :class="classList" v-model="value" :placeholder="placeholder" :rows="rows"
			:maxlength="maxLengthValue" :state="NonStatus === true ? null : v.errors.length !== 0 ? false : null"
			:disabled="disabled">
		</b-form-textarea>

		<span class="invalid-feedback" :style="{ color: '#ff0000' }" v-if="v.errors.length > 0">{{ errorMessage }}</span>
	</vvp>
</template>

<script>
export default {
	name: "ValidTextArea",
	data() {
		return {
			failedRules: null
		}
	},
	props: {
		rows: {
			type: Number,
			default: 5,
		},
		placeholder: {
			type: String,
			default: "",
		},
		inputType: {
			type: String,
			default: "",
		},
		classList: {
			type: String,
			default: "",
		},
		inputValue: {
			require: true,
			type: String || Number,
			default: "",
		},
		vid: {
			type: String,
			default: "",
		},
		NonStatus: {
			type: Boolean,
			default: false,
		},
		/**
		 *  validation 체크 룰 설정
		 *  @values non-null, abc
		 */

		rules: {
			required: true,
			type: String,
			default: "",
		},
		errorMessage: {
			type: String,
			default: "",
		},
		textareaRow: {
			type: Number,
			default: 1,
		},
		/** disabled 여부 */
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		value: {
			get() {
				return this.inputValue;
			},
			set(value) {
				this.$emit("update:inputValue", value);
			},
		},
		maxLengthValue() {
			let length = this.rules.includes("max:") ? (this.rules.split("max:")[1].includes("|") ? Number(this.rules.split("max:")[1].split("|")[0]) : Number(this.rules.split("max:")[1])) : null;

			return length;
		},
	},
	mounted() { },

	methods: {
		changeMethod(value) {
			this.$emit(this.changeMethodName, value);
		},
		setFailedRules(v) {
			this.failedRules = v.failedRules;
		}
	},
};
</script>

<style scoped>
textarea {
	min-height: 30px;
}

.desc-ctn {
	resize: none;
	overflow-y: auto;
}
</style>
